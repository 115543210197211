export default {
  "ATTENTION: without the unique return code, your package cannot be identified.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POZOR: bez jedinečného návratového kódu nie je možné váš balík identifikovať."])},
  "Bank account number starts with %s (example: %s).": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bankového účtu začína s %s (príklad: %s)."])},
  "Be sure to include your unique complaint number with the product in the package. Your unique complaint number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uistite sa, že ste do balenia zahrnuli svoje jedinečné číslo sťažnosti s výrobkom. Vaše jedinečné číslo sťažnosti je"])},
  "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komentár"])},
  "Damaged product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poškodený výrobok"])},
  "Greetings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozdravy"])},
  "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ahoj!"])},
  "Information is not required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie sa nevyžadujú"])},
  "No reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadny dôvod"])},
  "Once your return has been received at our offices, an e-mail will be sent to the address entered in the complaint form where we will inform you about the latest updates on the return process.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po doručení vášho návratu do našich kancelárií bude zaslaný e-mail na adresu uvedenú vo formulári sťažnosti, kde Vás budeme informovať o najnovších aktualizáciách procesu vrátenia."])},
  "Order number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo objednávky"])},
  "Paid on our website, and not with the delivery service / courier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platené na našich webových stránkach, a nie s doručovacou službou/kuriérom"])},
  "Product quality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kvalita výrobku"])},
  "Product replacement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmena produktu"])},
  "Product return procedure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postup vrátenia produktu"])},
  "Product return process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces vrátenia produktu"])},
  "Reason for the complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dôvod sťažnosti"])},
  "Refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátenie peňazí"])},
  "Refund form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulár vrátenia"])},
  "Returning the product is quick and easy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátenie produktu je rýchle a jednoduché"])},
  "Returning the product is quick and easy.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátenie produktu je rýchle a jednoduché"])},
  "STEP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krok"])},
  "Select type of complaint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zvoľte typ sťažnosti"])},
  "Send the package to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odošlite balík na"])},
  "Send the package to the following address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odošlite balík na nasledujúcu adresu:"])},
  "Something went wrong, try again later or contact our support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niečo sa pokazilo, skúste to znova neskôr alebo kontaktujte našu podporu"])},
  "Submit the form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať formulár"])},
  "The bank account number starts with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bankového účtu začína s %s (príklad: %s)."])},
  "The return procedure has just been forwarded to your e-mail address:": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postup vrátenia bol práve preposlaný na vašu e-mailovú adresu:"])},
  "Together with the product to be returned, enter your unique return code in the package.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spolu s produktom, ktorý sa má vrátiť, zadajte svoj jedinečný návratový kód do balíka.\n"])},
  "Type of payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Druh platby"])},
  "When we receive the package in our branch, you will be informed about the course of the complaint to your e-mail address, which you provided when submitting the complaint form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keď obdržíme balík v našej pobočke, budete informovaní o priebehu sťažnosti na Vašu e-mailovú adresu, ktorú ste zadali pri odosielaní reklamného formulára"])},
  "Wrong product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávny produkt"])},
  "Wrong quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávne množstvo"])},
  "You can print out already completed information to help return the product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete vytlačiť už dokončené informácie, ktoré vám pomôžu vrátiť produkt"])},
  "You have already submitted a return request. Be patient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Už ste odoslali žiadosť o vrátenie. Buďte trpezlivý"])},
  "You have received a PDF file for return procedure in the attachment.": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostali ste súbor PDF na postup vrátenia v prílohe."])},
  "Your unique return number is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše jedinečné spiatočné číslo je"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesto"])},
  "complaint number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["číslo sťažnosti"])},
  "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príklad"])},
  "first name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krstné meno"])},
  "instructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokyny"])},
  "last name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priezvisko"])},
  "post code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PSČ"])},
  "reciever": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["receptov"])},
  "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielateľ"])}
}